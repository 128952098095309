import "./ChatLandingScreen.css";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";
import {ChatUtil} from "../../../util/ChatUtil";
import {DataManager} from "../../../util/DataManager";
import {ServiceUtil} from "../../../util/ServiceUtil";
import {ImageUtil} from "../../../util/ImageUtil";
import {AppConfig} from "../../../util/AppConfig";
import { v4 as uuidv4 } from "uuid";

import Rosetta from "../../../rosetta/Rosetta";

import sendKnockoutIcon from "../../../assets/send_knockout.svg";
import Linkify from 'linkify-react';
import tinycolor from "tinycolor2";
import {ChatNavigation} from "./ChatNavigation";

export const ChatLandingScreen = (props) => {

    const [ready, setReady] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [items, setItems] = useState([]);
    const [isTyping, setIsTyping] = useState(false);

    const [chatFlowId, setChatFlowId] = useState(AppConfig.getConfig(AppConfig.keys.CHAT_FLOW_ID, 255)); // TODO 37
    const [sessionID, setSessionID] = useState(AppConfig.getConfig(AppConfig.keys.CHAT_FLOW_SESSION_ID, null));
    const [lastUUID, setLastUUID] = useState(undefined);
    const [upcomingUUID, setUpcomingUUID] = useState(undefined);

    const [expectingStreamedResponse, setExpectingStreamedResponse] = useState(false);
    const [expectingHTML, setExpectingHTML] = useState(false);

    const [nextTrigger, setNextTrigger] = useState(0);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [messageError, setMessageError] = useState(false);

    const [textInputShown, setTextInputShown] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [textUuid, setTextUuid] = useState(null);

    const chatTimeout = useRef();
    const typingTime = useRef(0);
    const scrollIntoViewElem = useRef(null);
    const userMessages = useRef({});

    const [gptMessage, setGptMessage] = useState("");

    const authTokenCallback = useRef();
    const chatTextInput = useRef();

    useEffect(() => {
        const authToken = DataManager.getAuthToken();
        if (authToken) {
            signalReady();
        } else {
            DataManager.addCallback((dataKey, value) => {
                if (dataKey === DataManager.keys.authToken) {
                    if (!ready && value) {
                        signalReady();
                    }
                }
            });
        }

        setSessionID(AppConfig.getConfig(AppConfig.keys.CHAT_FLOW_SESSION_ID, sessionID));

        window._uwbInitialChatFlow = () => {
            console.log("CHAT WAS REQUESTED TO RESTART. RESTARTING...");
            setChatFlowId(AppConfig.getConfig(AppConfig.keys.CHAT_FLOW_ID, 255));
            setLastUUID(null);
            userMessages.current = {};

            getNextMessage();
        }

        window._uwbPluginChangeChatFlow = (chatFlowId) => {
            if (chatFlowId) {
                setChatFlowId(chatFlowId);
            }
        }

        // Check for changes to Auth Token
        authTokenCallback.current = (key, value) => {
            if (key === DataManager.keys.authToken) {
                getNextMessage();
            }
        }
        DataManager.addCallback(authTokenCallback.current);

        window.__uwbchatuimessage = (message) => {
            // console.log("Received Chat GPT Message: " + message);
            setGptMessage(message);
        }

        return () => {
            clearTimeout(chatTimeout.current);
            window._uwbPluginChangeChatFlow = undefined;
            window.__uwbchatuimessage = undefined;
        }
    }, []);

    useEffect(() => {
        if (chatFlowId) {
            setItems([]);
            setNetworkInFlight(false);
            setLastUUID(undefined);
            setTextInputShown(false);
            setTextInput("");
            setCurrentMessage(null);
            setIsTyping(false);
            clearTimeout(chatTimeout.current);
            userMessages.current = {};
            typingTime.current = 0;
            setHasMoreItems(true);
            setGptMessage("");
            setTextUuid(undefined);
            setExpectingStreamedResponse(false);
            setExpectingHTML(false);

            getNextMessage();
        }
    }, [chatFlowId]);

    useEffect(() => {
        if (ready) {
            fetchNextMessageFromNetwork();
        }
    }, [ready]);

    useEffect(() => {
        if (currentMessage) {
            processChatMessage(currentMessage);
            setCurrentMessage(null);
        }
    }, [currentMessage]);

    useEffect(() => {
        if (!ready || !hasMoreItems) {
            // console.log("Next fetch blocked. Ready: " + ready + " :: More items: " + hasMoreItems);
            return;
        }

        // console.log("GET NEXT MESSAGE: " + lastUUID);

        if (typingTime.current > 0) {
            // Show the typing indicator
            setIsTyping(true);
            chatTimeout.current = setTimeout(() => {
                getNextMessage();
            }, typingTime.current * 1000);
            typingTime.current = 0;
        } else {
            if (expectingStreamedResponse) {
                // console.log("GET STREAM...");
;                fetchNextMessageGPTFromNetwork((action, data) => {
                    // console.log("GTP: " + action, data);
                });
            } else {
                // console.log("GET NORMAL...");
                fetchNextMessageFromNetwork();
            }
        }
        // console.log("End Trigger change...");
    }, [nextTrigger]);

    useEffect(() => {
        scrollIntoViewElem.current.scrollIntoView({behavior:"smooth"});
    }, [items, isTyping, gptMessage]);

    function signalReady() {
        if (!ready) {
            // There's a bit of a timing issue with this approach to signal ready
            // so we delay the ready signal by a few hundred milliseconds to
            // ensure the auth token is in place before we call the API.
            setTimeout(() => {
                setReady(true);
            }, 200);
        }
    }

    function moveToService(serviceId) {
        ServiceUtil.resolveServiceAction(serviceId, (action, data) => {
            if (action === "error") {
                console.log(data);
            }
        });
    }

    function processChatMessage(message) {
        // Clear typing indicator now
        setIsTyping(false);

        // console.log(message);

        let haveMoreItems = CommonUtil.getOrDefault(message, "haveMoreItems", true);
        setHasMoreItems(haveMoreItems);

        const streamedRequest = CommonUtil.getOrDefault(message, "streamRequested", false);
        setExpectingStreamedResponse(streamedRequest);

        if (streamedRequest) {
            if (message.hasOwnProperty("item")) {
                const item = message.item;

                let isHTML = CommonUtil.getOrDefault(item, "isHTML", false);
                if (!isHTML) {
                    if (item.hasOwnProperty("data")) {
                        if (item.data.hasOwnProperty("isHTML")) {
                            isHTML = item.data.isHTML;
                        }
                    }
                }
                setExpectingHTML(isHTML);

                // We will hold this UUID until AFTER this streaming request has completed
                const uuid = CommonUtil.getOrDefault(item, "uuid", undefined);
                setUpcomingUUID(uuid);

                getNextMessage();
            }
        } else {
            if (message.hasOwnProperty("item")) {
                const item = message.item;
                const data = CommonUtil.getOrDefault(item, "data", {});

                item.localId = uuidv4();

                let typeID = parseInt(CommonUtil.getOrDefault(item, "typeID", 0));
                if (typeID === ChatUtil.messageTypes.TEXT_FIELD) {
                    setTextInputShown(true);
                    setTextUuid(CommonUtil.getOrDefault(data, "uuid"));

                    setTimeout(() => {
                        if (chatTextInput) {
                            chatTextInput.current.focus();
                            chatTextInput.current.click();
                        }
                    }, 100);
                }

                let uuid = CommonUtil.getOrDefault(item.data, "uuid");
                if (uuid) {
                    setLastUUID(uuid);
                }

                // Do not automatically fetch for Answers and Text Fields
                let shouldAutoFetchNextMessage = ![
                    ChatUtil.messageTypes.ANSWERS,
                    ChatUtil.messageTypes.TEXT_FIELD
                ].includes(typeID);

                if (shouldAutoFetchNextMessage && haveMoreItems) {
                    // How long the chat message should chill for
                    let displayTime = CommonUtil.getOrDefault(item.data, "displayTime", 1);
                    // How long the "typing" indicator should be shown for
                    typingTime.current = CommonUtil.getOrDefault(item.data, "typingTime", 1);

                    if (displayTime > 0) {
                        chatTimeout.current = setTimeout(() => {
                            getNextMessage();
                        }, displayTime * 1000);
                    } else {
                        getNextMessage();
                    }
                }

                const newItems = [...items];
                newItems.push(item);
                setItems(newItems);
            }
        }
    }

    function handleChatAnswer(uuid, text) {
        setLastUUID(uuid);

        // Clear down any answer items
        const newItems = [...items];
        for (let i = newItems.length - 1; i >= 0; i--) {
            let item = newItems[i];
            if (item) {
                let typeID = parseInt(CommonUtil.getOrDefault(item, "typeID", 0));
                if (typeID === ChatUtil.messageTypes.ANSWERS) {
                    newItems.splice(i, 1);
                }
            }
        }
        // Add a new "User" chat bubble with the selected answer
        newItems.push(ChatUtil.createUserResponseItem(text));
        setItems(newItems);

        getNextMessage();
    }

    function handleUserInput(uuid, text) {
        if (!text || text.trim() === "") {
            // Do not allow progression on an empty or malformed string
            return;
        }

        // Attempt to obtain the final UUID in the UUID chain.
        // Message UUIDs can come back as a chain of UUIDs that are seperated
        // with a "." character. We will want to use the last UUID in this
        // chain.
        let useUUID = uuid;
        if (uuid) {
            const uuidParts = uuid.split(".");
            if (uuidParts.length > 0) {
                useUUID = uuidParts[uuidParts.length - 1];
            }
        }

        userMessages.current[useUUID] = text;
        setTextInputShown(false);
        setTextInput("");

        // Find and remove any ChatTextInput items, replace with User Chat message
        const newItems = [...items];
        newItems.push(ChatUtil.createUserResponseItem(text));
        setItems(newItems);

        getNextMessage();
    }

    /** Informs the client to fetch the next message in the chat **/
    function getNextMessage() {
        setNextTrigger(Math.random());
    }

    function fetchNextMessageFromNetwork() {
        if (networkInFlight || !hasMoreItems) return;
        setNetworkInFlight(true);
        setMessageError(false);

        setIsTyping(true);

        const data = {
            chatFlowID : chatFlowId,
            sessionID,
            lastUUID,
            userMessages : userMessages.current
        };

        axios.post(ENDPOINTS.chat.getNextMessage, data)
            .then((r) => {
                // console.log(r);
                const resp = API.parse(r);
                if (resp.success) {
                    setCurrentMessage(resp.data);
                } else {
                    console.log(API.formatError(resp));
                    setMessageError(true);
                    setIsTyping(false);
                }

                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);

                setMessageError(true);
                setIsTyping(false);
            });
    }

    async function fetchNextMessageGPTFromNetwork(callback) {
        if (networkInFlight && hasMoreItems) return;
        setNetworkInFlight(true);

        setMessageError(false);

        const data = {
            chatFlowID : chatFlowId,
            lastUUID,
            sessionID,
            userMessages : userMessages.current
        };

        // console.log("REQUEST STREAM:", data);

        setIsTyping(true);

        let totalMessage = "";
        const stream = await generateStream(data);
        if (stream) {
            for await (const chunk of stream) {
                let terminateAfter = false;

                if (chunk) {
                    let processedChunk = chunk.replaceAll("^", "");

                    if (processedChunk.includes("<<<STREAM_BEGIN>>>")) {
                        processedChunk = processedChunk.replaceAll("<<<STREAM_BEGIN>>>", "");
                    }

                    if (processedChunk.includes("<<<STREAM_END>>>")) {
                        terminateAfter = true;
                        processedChunk = processedChunk.replaceAll("<<<STREAM_END>>>", "");
                    }

                    if (processedChunk.endsWith("undefined")) {
                        processedChunk = processedChunk.substring(Math.max(0, processedChunk.length - 9));
                    }

                    if (processedChunk.includes("<<<STREAM_ERROR>>>")) {
                        processedChunk = processedChunk.replace("<<<STREAM_ERROR>>>", "");
                    }

                    if (processedChunk.includes("<<<STREAM_ERROR_END>>>")) {
                        terminateAfter = true;
                        processedChunk = processedChunk.replaceAll("<<<STREAM_ERROR_END>>>", "");
                    }

                    if (processedChunk) {
                        totalMessage += processedChunk;

                        if (!terminateAfter) {
                            // console.log("Updating GPT message with: " + totalMessage);
                            setIsTyping(false);
                            //setGptMessage(totalMessage);
                            if (window.__uwbchatuimessage) {
                                window.__uwbchatuimessage(totalMessage)
                            }
                        }
                    }
                }

                if (terminateAfter) {
                    // console.log("CHAT: FORCING END");
                    break;
                }
            }

            setGptMessage("");
            // console.log("CHAT: TOTAL MESSAGE: ", totalMessage);
            if (totalMessage !== "") {
                const newItems = [...items];
                newItems.push(
                    ChatUtil.createServerResponseItem(totalMessage, expectingHTML === true)
                );
                setItems(newItems);
            }

            // console.log("Stream ended. Getting next message...");
            setNetworkInFlight(false);
            typingTime.current = 0;
            setExpectingStreamedResponse(false);

            let nextUUID = lastUUID;
            if (upcomingUUID) {
                nextUUID = upcomingUUID;
                setUpcomingUUID(undefined);
            }
            setLastUUID(nextUUID);

            getNextMessage();
        } else {
            console.log("STREAM ERROR!");
        }
    }

    async function generateStream(data) {
        const resp = await fetch(
            API.getAPIUrl(ENDPOINTS.chat.getNextMessage),
            {
                method : "POST",
                headers : API.getHttpHeaders("application/json", "text/octet-stream"),
                body : JSON.stringify(data)
            }
        );

        if (resp.status !== 200) {
            setMessageError(true);
            return false;
        }
        if (!resp.body) {
            setMessageError(true);
            return false;
        }
        return getIterableStream(resp.body);
    }

    async function* getIterableStream(body) {
        const reader = body.getReader();
        const decoder = new TextDecoder();

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                break;
            }

            const decodedChunk = decoder.decode(value, {stream: true});
            if (decodedChunk) {
                yield decodedChunk;
            }
        }
    }

    // RENDER

    let typingElem = [];
    if (isTyping) {
        typingElem = (<TypingIndicator />);
    }

    let textInputElem = [];
    if (textInputShown) {
        let submitAccessibility = Rosetta.string("chat.accessibility_chat_input_send");
        let submitClass = "";
        if (textInput.trim() === "") {
            submitClass = " disabled";
            submitAccessibility = Rosetta.string("chat.accessibility_chat_input_send_disabled");
        }

        let sendButtonStyle = {};
        const headerColour = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR);
        if (headerColour) {
            let useColour = headerColour;
            const tcColour = tinycolor(headerColour);
            if (tcColour.isValid()) {
                if (tcColour.isLight()) {
                    useColour = tcColour.darken(30).toString();
                }
            }
            sendButtonStyle.backgroundColor = useColour;
        }

        textInputElem = (
            <form
                className={"chat-text-input"}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleUserInput(textUuid, textInput);
                }}>
                <div className={"chat-text-input-text"}>
                    <input
                        type="text"
                        value={textInput}
                        tabIndex={0}
                        autoFocus={true}
                        ref={chatTextInput}
                        aria-label={Rosetta.string("chat.accessibility_chat_input_text")}
                        placeholder={Rosetta.string("chat.input_hint")}
                        onChange={(e) => setTextInput(e.target.value)} />
                </div>

                <div className={"chat-text-input-submit" + submitClass} style={sendButtonStyle}>
                    <input
                        type={"submit"}
                        value={""}
                        className={"input-overlay"}
                        tabIndex={0}
                        aria-label={submitAccessibility}
                        style={{
                            backgroundImage : ImageUtil.background(sendKnockoutIcon)
                        }} />
                </div>
            </form>
        )
    }

    let gptElem = [];
    if (gptMessage !== "") {
        // console.log("GPT MESSAGE: " + gptMessage);
        gptElem = (
            <GPTText text={gptMessage} isHTML={expectingHTML} />
        );

    }

    const chatElems = items.map((item, index) => {
        const typeID = CommonUtil.getOrDefault(item, "typeID", ChatUtil.messageTypes.MESSAGE);

        let data = item.data;

        let autoFocus = items.length === 1 && index === (items.length - 1);

        let newItem = null;
        switch (typeID) {
            case ChatUtil.messageTypes.MESSAGE:
                let isHTML = false;
                if (item.hasOwnProperty("isHTML")) {
                    isHTML = item.isHTML;
                }
                if (!isHTML) {
                    isHTML = item.hasOwnProperty("data") && item.data.isHTML === true;
                }

                newItem = (
                    <ChatText
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        type={ChatTextType.SERVER}
                        autoFocus={autoFocus}
                        isHTML={isHTML}
                        text={CommonUtil.getOrDefault(data, "text", "")}
                    />
                );
                break;
            case ChatUtil.messageTypes.USER_RESPONSE:
                newItem = (
                    <ChatText
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        type={ChatTextType.USER}
                        text={CommonUtil.getOrDefault(data, "text", "")}
                        autoFocus={autoFocus}
                    />
                );
                break;
            case ChatUtil.messageTypes.ANSWERS:
                const answers = CommonUtil.getOrDefault(data, "answers");

                newItem = [];
                answers.forEach((answer) => {
                    newItem.push(
                        <ChatAnswer
                            key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                            uuid={CommonUtil.getOrDefault(answer, "uuid")}
                            text={CommonUtil.getOrDefault(answer, "text")}
                            autoFocus={autoFocus}
                            callback={handleChatAnswer}
                        />
                    );
                });

                break;
            case ChatUtil.messageTypes.SYSTEM_NOTIFICATION:
                newItem = (
                    <SystemNotification
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        text={CommonUtil.getOrDefault(data, "text")}
                        autoFocus={autoFocus}
                    />
                );
                break;
            case ChatUtil.messageTypes.TEXT_FIELD:
                newItem = [];
                newItem.push(
                    <ChatText
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        type={ChatTextType.SERVER}
                        text={CommonUtil.getOrDefault(data, "text")} />
                );

                break;
            case ChatUtil.messageTypes.IMAGE:
                newItem = (
                    <ChatImage
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        url={CommonUtil.getOrDefault(data, "url")}
                        altText={CommonUtil.getOrDefault(data, "altText")}
                        width={CommonUtil.getOrDefault(data, "width")}
                        height={CommonUtil.getOrDefault(data, "height")}
                        autoFocus={autoFocus}
                    />
                );
                break;
            case ChatUtil.messageTypes.SERVICE_LINK:
                newItem = (
                    <ServiceLink
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        serviceID={CommonUtil.getOrDefault(data, "serviceID")}
                        title={CommonUtil.getOrDefault(data, "title")}
                        subtitle={CommonUtil.getOrDefault(data, "subtitle")}
                        typeName={CommonUtil.getOrDefault(data, "typeName")}
                        imageURL={CommonUtil.getOrDefault(data, "imageURL")}
                        autoFocus={autoFocus}
                        callback={(serviceId) => {
                            moveToService(serviceId);
                        }}
                    />
                );
                break;
            case ChatUtil.messageTypes.APP_STORE_REVIEW:
                break;
            case ChatUtil.messageTypes.ANIMATED_SVG:
                newItem = (
                    <SVGAnimation
                        key={CommonUtil.getOrDefault(item, "localId", uuidv4())}
                        code={CommonUtil.getOrDefault(data, "code")}
                        size={CommonUtil.getOrDefault(data, "size")}
                        altText={CommonUtil.getOrDefault(data, "altText")}
                        autoFocus={autoFocus}
                    />
                );
                break;
        }

        return newItem;
    })

    if (messageError) {
        chatElems.push(
            <div className={"chat-error"}>
                <div className={"error-message"}>
                    {Rosetta.string("chat.error_title")}
                </div>
                <div className={"error-action"} onClick={() => getNextMessage()}>
                    {Rosetta.string("chat.error_action")}
                </div>
            </div>
        );
    }

    return (
        <div className={"chat-landing-screen"}>
            <ChatNavigation />

            <div className={"chat-container"}>
                <div className={"pusher"}/>
                <div className={"spacer"}/>

                {chatElems}

                {gptElem}

                {typingElem}

                <div ref={scrollIntoViewElem}/>

                {textInputElem}
            </div>
        </div>
    )

}

const TypingIndicator = (props) => {
    return (
        <div
            key={"chat-typing-indicator-1234"}
            className={"chat-bubble-container left"}
            tabIndex={0}
            aria-label={Rosetta.string("chat.accessibility_chat_typing")}
            role={"presentation"}
        >
            <div className={"chat-bubble"}>
                <span className={"typing"}>
                    <span className={"dot dot-1"}/>
                    <span className={"dot dot-2"}/>
                    <span className={"dot dot-3"}/>
                </span>
            </div>
        </div>
    );
}

const ChatTextType = {
    USER: 1,
    SERVER: 2
}

const ChatText = (props) => {
    const {key} = props;
    const {type} = props;
    const {text} = props;
    const {isHTML} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    let accessibilityString = "";
    let direction = "left";
    if (type === ChatTextType.USER) {
        direction = "right";
        accessibilityString = Rosetta.string("chat.accessibility_chat_from_you", { message : text });
    } else {
        accessibilityString = Rosetta.string("chat.accessibility_chat_from_assistant", { message : text });
    }

    let textElems = [];
    if (text && text !== "") {
        textElems = text.split("\n").map((line) => {
            if (isHTML) {
                // CADE 16/08/24 - Linkify will not work when setting HTML directly.
                // So find and replace links and URIs manually.
                let treatedLine = CommonUtil.linkifyString(line);

                return (
                    <div
                        tabIndex={-1}
                        aria-hidden={true}
                        dangerouslySetInnerHTML={{__html: treatedLine}}
                    />
                )
            } else {
                return (
                    <div tabIndex={-1} aria-hidden={true}>
                        {line}
                    </div>
                )
            }
        });

        return (
            <div className={"chat-bubble-container " + direction}>
                <div
                    key={key}
                    className={"chat-bubble"}
                    tabIndex={0}
                    aria-label={accessibilityString}
                    role={"presentation"}
                    autoFocus={autoFocus}
                    ref={focusableElem}
                >
                <span className={"text"}>
                    <Linkify options={{
                        target: "_blank",
                        attributes: {
                            onClick: CommonUtil.interceptLink
                        }
                    }}>
                        {textElems}
                    </Linkify>
                </span>
                </div>
            </div>
        );
    }

    return [];
}

const GPTText = (props) => {
    const {key} = props;
    const {text} = props;
    const {autoFocus} = props;
    const {isHTML} = props;
    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    const accessibilityString = Rosetta.string("chat.accessibility_chat_from_assistant", {message: text});

    let textElem = (<span className={"text"}>{text}</span>);
    if (isHTML) {
        textElem = (
            <span className={"text"} dangerouslySetInnerHTML={{__html: text }}></span>
        );
    }

    return (
        <div
            key={key}
            className={"chat-bubble-container"}
            tabIndex={0}
            aria-label={accessibilityString}
            autoFocus={autoFocus}
            role={"presentation"}
            ref={focusableElem}
        >
            <div className={"chat-bubble"}>
                {textElem}
            </div>
        </div>
    );
}

const ChatAnswer = (props) => {
    const {key} = props;
    const {uuid} = props;
    const {text} = props;
    const {callback} = props;

    function handleCallback(uuid, text) {
        if (callback) {
            callback(uuid, text);
        }
    }

    return (
        <div className={"chat-bubble-container centre"}>
            <div
                key={key}
                className={"chat-bubble answer"}
                tabIndex={0}
                aria-label={Rosetta.string("chat.accessibility_chat_answer", { message : text })}
                role={"button"}
                onClick={() => handleCallback(uuid, text)}>
                {text}
            </div>
        </div>
    );
}

const SystemNotification = (props) => {
    const {key} = props;
    const {text} = props;
    const {autoFocus} = props;
    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    return (
        <div className={"chat-bubble-container centre"}>
            <div
                key={key}
                className={"system-notification"}
                tabIndex={0}
                aria-label={text}
                role={"presentation"}
                autoFocus={autoFocus}
            >
                {text}
            </div>
        </div>
    );
}

const ChatImage = (props) => {
    const {key} = props;
    const {url} = props;
    const {width} = props;
    const {height} = props;
    const {altText} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    const MAX_SIZE = 350;

    let useWidth = width;
    let useHeight = height;

    if (useWidth > MAX_SIZE) {
        let diff = MAX_SIZE / useWidth;
        useWidth = MAX_SIZE;
        useHeight = parseInt(useHeight * diff);
    } else if (useHeight > 400) {
        let diff = MAX_SIZE / useHeight;
        useHeight = MAX_SIZE;
        useWidth = parseInt(useWidth * diff);
    }

    return (
        <div className={"chat-bubble-container centre"}>
            <img
                key={key}
                src={url}
                className={"image-item"}
                alt={altText}
                tabIndex={0}
                aria-label={Rosetta.string("chat.accessibility_chat_from_assistant", { message : altText })}
                role={"img"}
                autoFocus={autoFocus}
                ref={focusableElem}
                style={{
                    width : useWidth + "px",
                    height : useHeight + "px"
                }}
            />
        </div>
    )
}

const ServiceLink = (props) => {
    const {key} = props;
    const {serviceID} = props;
    const {title} = props;
    const {subtitle} = props;
    const {typeName} = props;
    const {imageURL} = props;
    const {callback} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    function handleCallback() {
        if (callback) {
            callback(serviceID);
        }
    }

    // ACCESSIBILITY STRING
    let accessibilityContent = "";
    if (title) {
        accessibilityContent += title;
    }

    if (subtitle) {
        if (accessibilityContent !== "") {
            accessibilityContent += " - ";
        }
        accessibilityContent += subtitle;
    }

    if (typeName) {
        if (accessibilityContent !== "") {
            accessibilityContent += " - ";
        }
        accessibilityContent += typeName;
    }

    let accessibilityInner = Rosetta.string("chat.accessibility_chat_from_assistant", { message : accessibilityContent });
    let accessibilityString = Rosetta.string("chat.accessibility_service_link", { message : accessibilityInner });
    // ACCESSIBILITY STRING

    return (
        <div className={"chat-bubble-container"} key={key}>
            <div className={"service-link"} onClick={() => handleCallback()}>
                <div className={"service-link-image"} tabIndex={-1} aria-hidden={true} style={{backgroundImage: "url(" + imageURL + ")"}}/>
                <div className={"service-link-text type"} tabIndex={-1} aria-hidden={true}>{typeName}</div>
                <div className={"service-link-text"} tabIndex={-1} aria-hidden={true}>{title}</div>
                <div className={"service-link-text"} tabIndex={-1} aria-hidden={true}>{subtitle}</div>

                <div className={"service-link-button"}
                     tabIndex={0}
                     aria-label={accessibilityString}
                     role={"button"}
                     ref={focusableElem}
                >
                    Open
                </div>
            </div>
        </div>
    )

}

const SVGAnimation = (props) => {
    const {key} = props;
    const {code} = props;
    const {size} = props;
    const {altText} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    let classExtra = "";
    if (["small", "medium", "large"].includes(size.toLowerCase())) {
        classExtra += " " + size;
    }

    const accessibilityString = Rosetta.string("chat.accessibility_chat_from_assistant", { message : altText });

    return (
        <div
            key={key}
            className={"chat-bubble-container centre"}
            tabIndex={0}
            aria-label={accessibilityString}
            autoFocus={autoFocus}
            role={"img"}
            ref={focusableElem}
        >
            <iframe
                className={"image-item" + classExtra}
                srcDoc={code}
                scrolling={"no"}
                tabIndex={-1}
                aria-hidden={true}
            />
        </div>
    )
}