import "./ThemedButton.css";
import {AppConfig} from "../../util/AppConfig";

export const ThemedButton = (props) => {

    const {label} = props;
    const {onClick} = props;
    const {ariaLabel} = props;

    // RENDER

    let useAriaLabel = ariaLabel;
    if (!useAriaLabel) {
        useAriaLabel = label;
    }

    const backgroundColor = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR, "#EFEFEF");
    const style = {
        backgroundColor
    };

    const headerTheme = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "dark");
    let classExtra = " " + headerTheme;

    return (
        <button
            className={"themed-button" + classExtra}
            tabIndex={0}
            aria-label={useAriaLabel}
            style={style}
            onClick={onClick}
        >
            {label}
        </button>
    )

}