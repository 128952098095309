import Rosetta from "../../rosetta/Rosetta";
import {ImageUtil} from "../../util/ImageUtil";
import {Navigator} from "../../navigator/Navigator";
import {AppConfig} from "../../util/AppConfig";

import iconBack from "../../assets/ic_back.svg";
import {AuthUtil} from "../../util/AuthUtil";
import {PodcastFeedbackScreen} from "../screens/service/PodcastFeedbackScreen";

export const AppHeader = (props) => {

    const {hasBackEntries} = props;

    const headerTheme = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "");
    const headerBackground = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR, "#003A65");
    const headerTitle = AppConfig.getConfig(AppConfig.keys.HEADER_TITLE, Rosetta.string("common.app_name"));

    function headerTitleWasClicked() {
        // Navigator.navigate(
        //     <PodcastFeedbackScreen />
        // );
    }

    // RENDER

    let backButton = (<div className={"header-action hide"}/>);
    if (hasBackEntries) {
        backButton = (
            <div
                className={"header-action icon invert"}
                style={{backgroundImage : ImageUtil.background(iconBack)}}
                tabIndex={0}
                aria-label={Rosetta.string("common.accessibility_back")}
                role={"button"}
                onClick={() => {
                    Navigator.back();
                }}
            />
        );
    }

    const headerStyle = {};
    if (headerBackground) {
        headerStyle.backgroundColor = headerBackground;
    }

    let headerClassExtra = "";
    if (headerTheme) {
        if (["light", "dark"].includes(headerTheme.toLowerCase().trim())) {
            headerClassExtra = headerTheme;
        }
    }

    return (
        <div className={"plugin-header " + headerClassExtra} style={headerStyle}>

            {backButton}
            <div className={"header-action hide"}/>

            <div
                className={"header-title"}
                tabIndex={0}
                aria-label={Rosetta.string("common.accessibility_title")}
                onClick={headerTitleWasClicked}
            >
                {headerTitle}
            </div>

            <div className={"header-action hide"}/>
            <div className={"header-action hide"}/>

        </div>
    )

    // Hey! The header-action items that are all hidden are there purposefully.
    // The JS that will launch the Plugin in an iFrame will overlay actions
    // in these spaces. So be careful not to put stuff there!

}