import {UrlUtil} from "./UrlUtil";
import {CommonUtil} from "./CommonUtil";
import axios from "axios";
import {API, ENDPOINTS} from "../network/API";

export const AppConfig = {
    _configData : {},
    _configId : undefined,
    _callbacks : [],
    _ready : false,
    init : () => {
        // Try and parse config from the query string
        const configData = UrlUtil.getQueryParameter("config");
        if (configData) {
            let decodedConfig = decodeURIComponent(configData);
            if (decodedConfig) {
                decodedConfig = atob(decodedConfig);
                if (decodedConfig) {
                    decodedConfig = JSON.parse(decodedConfig);
                }
            }

            if (decodedConfig) {
                AppConfig._configData = decodedConfig;
                AppConfig._ready = true;

                AppConfig._triggerCallbacks(
                    AppConfig.actions.LOADED
                );
            }
        }

        // If we didn't get config in the query params, look to see if we
        // have a config ID instead and pull data from the API.
        const configId = UrlUtil.getQueryParameter("configId");
        if (configId) {
            AppConfig.setConfigId(configId);
            AppConfig._fetchConfigFromNetwork();
        }
    },
    isReady : () => {
        return AppConfig._ready;
    },
    getConfigId : () => {
        return AppConfig._configId;
    },
    setConfigId : (configId) => {
        AppConfig._configId = configId;
    },
    getConfigData : () => {
        return AppConfig._configData;
    },
    getConfig : (key, defaultValue) => {
        return CommonUtil.getOrDefault(AppConfig._configData, key, defaultValue);
    },
    _fetchConfigFromNetwork : () => {
        AppConfig._triggerCallbacks(
            AppConfig.actions.LOADING
        );

        axios.get(ENDPOINTS.plugin.getConfig + AppConfig._configId)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data) {
                        const useData = resp.data;

                        if (useData) {
                            const queryEmotion = UrlUtil.getQueryParameter("emotion");
                            console.log("APP CONFIG LOAD. Query emotion:", queryEmotion);
                            if (queryEmotion) {
                                useData[AppConfig.keys.INCOMING_EMOTION] = queryEmotion;
                            }
                        }

                        AppConfig._configData = useData;
                        AppConfig._ready = true;

                        console.log("APP CONFIG: ", AppConfig._configData);

                        AppConfig._triggerCallbacks(
                            AppConfig.actions.LOADED,
                            AppConfig._configData
                        );
                    }
                } else {
                    console.log(API.formatError(resp));

                    AppConfig._triggerCallbacks(
                        AppConfig.actions.ERROR
                    );
                }
            })
            .catch((e) => {
                console.log(e);
            });
    },
    addCallback : (callback) => {
        AppConfig._callbacks.push(callback);
    },
    removeCallback : (callback) => {
        for (let i = AppConfig._callbacks.length; i >= 0; i--) {
            if (AppConfig._callbacks[i] === callback) {
                delete AppConfig._callbacks[i];
            }
        }
    },
    _triggerCallbacks : (action, data) => {
        AppConfig._callbacks.forEach((callback) => {
            try {
                callback(action, data);
            } catch (e) {
                console.log(e);
            }
        });
    },
    getState : () => {
        return {
            configId : AppConfig._configId,
            configData : AppConfig._configData
        };
    },
    setState : (state) => {
        if (state) {
            if (state.hasOwnProperty("configId")) {
                AppConfig._configId = state.configId;
            }

            if (state.hasOwnProperty("configData")) {
                AppConfig._configData = state.configData;
            }

            AppConfig._ready = true;

            AppConfig._triggerCallbacks(
                AppConfig.actions.LOADED
            );
        }
    },
    keys : {
        CHAT_FLOW_ID : "chatFlowID",
        SOS_SERVICE_ID : "sosServiceID",
        MENU_SERVICE_ID : "menuServiceID",
        HEADER_THEME : "headerTheme",
        HEADER_BACKGROUND_COLOUR : "headerBackgroundColour",
        HEADER_TITLE : "headerTitle",
        DEFAULT_USER_ROLE_ID : "defaultUserRoleID",
        DEFAULT_LOCATION_ID : "defaultLocationID",
        INCOMING_EMOTION : "incomingEmotionId",
        WIDGET_ALIGNMENT : "widgetAlignment",
        WIDGET_BORDER_COLOUR : "widgetBorderColour",
        WIDGET_BACKGROUND_COLOUR : "widgetBackgroundColour",
        WIDGET_TEXT_COLOUR : "widgetTextColour",
        WIDGET_TITLE : "widgetTitle",
        WIDGET_SHOW_ICON : "widgetShowIcon",
        WIDGET_SHOW_EMOTIONS : "widgetShowEmotions",
        CHAT_FLOWS : "chatFlows",
        SUPPORT_SECTION_ID : "supportSectionID",
        SUPPORT_SECTION_TITLE : "supportSectionTitle",
        CHAT_SHOW_NAVIGATION : "chatShowNavigation",
        CHAT_SHOW_SOS : "chatShowSos",
        CHAT_SHOW_CHARLEY : "chatShowCharley",
        CHAT_SHOW_QR_LINK : "chatShowQRLink",
        CHAT_SHOW_OVERFLOW : "chatShowOverflow",
        WELCOME_SCREEN_SHOWN : "welcomeScreenShown",
        WELCOME_SCREEN_IMAGE_SHOWN : "welcomeScreenImageShown",
        WELCOME_SCREEN_MESSAGE : "welcomeScreenMessage",
        WELCOME_SCREEN_BUTTON_LABEL : "welcomeScreenButtonLabel",
        WIDGET_ASSISTANT_IMAGE_ID : "widgetAssistantImageID",
        WIDGET_ASSISTANT_IMAGE_URL : "widgetAssistantImageUrl",
        WELCOME_SCREEN_ASSISTANT_IMAGE_ID : "welcomeScreenAssistantImageID",
        WELCOME_SCREEN_ASSISTANT_IMAGE_URL : "welcomeScreenAssistantImageUrl",
        ALLOW_PERSISTENT_STORAGE : "allowPersistentStorage",
        CHAT_FLOW_SESSION_ID : "sessionID"
    },
    emotions : {
        EMOTION_HAPPY : 25,
        EMOTION_NEUTRAL : 26,
        EMOTION_SAD : 1
    },
    actions : {
        LOADING : "appconfig.loading",
        LOADED : "appconfig.loaded",
        ERROR : "appconfig.error"
    }
}